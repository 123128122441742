<template>
  <template v-if="hasRole">
    <slot />
  </template>
</template>

<script setup>
import _ from "lodash"
import { computed } from "vue"

import { store } from "@/services/store.service"

const props = defineProps({
  role: {
    type: [Array, String],
    default: null,
  },
  subString: {
    type: String,
    default: null,
  },
})

/**
 * Check if the user has ALL the given roles.
 * @type {ComputedRef<boolean>}
 */
const hasRole = computed(() => {
  // role match
  if (props.role !== null) {
    // if role is array it's a 100% match
    if (Array.isArray(props.role)) {
      return _.difference(props.role, store.user?.roles).length === 0
    }

    // if prop is string, check if user has the role
    return store.user?.roles.includes(props.role)
  }

  // substring match
  if (props.subString !== null) {
    return store.user?.roles.some((role) => role.includes(props.subString))
  }

  return false
})
</script>
