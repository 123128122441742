<template>
  <span
    v-if="env"
    :id="id"
    :style="cssStyle"
    data-bs-toggle="tooltip"
    data-bs-placement="bottom"
    data-bs-delay='{"show":250,"hide":15}'
    :data-bs-title="env.title"
    :class="env.class"
    class="badge text-uppercase cursor-help"
  >
    {{ env.label }}
  </span>
</template>

<script>
import { Tooltip } from "bootstrap"

export default {
  name: "EnvironmentBadge",
  props: {
    id: {
      type: String,
      default:
        "EnvironmentBadge" + (Math.random() + 1).toString(36).substring(8),
    },
    cssStyle: { type: String, required: true },
  },
  computed: {
    env() {
      if (process.env.VUE_APP_ENV === "production") {
        return false
      }

      if (process.env.VUE_APP_ENV === "develop") {
        return {
          label: "testsystem",
          title: "Dies ist ein Testsystem. Daten gehen verloren.",
          class: "bg-danger",
        }
      }

      return {
        label: process.env.VUE_APP_ENV,
        title: "🌈🦄🤠🐸",
        class: "bg-dark",
      }
    },
  },
  mounted() {
    if (this.env) {
      this.$nextTick(() => {
        const tooltipEl = document.querySelector(`#${this.id}`)
        new Tooltip(tooltipEl)
      })
    }
  },
}
</script>

<style scoped>
.cursor-help {
  cursor: help;
}
</style>
