<template>
  <div class="toast-container position-fixed bottom-0 end-0 p-3">
    <div
      v-for="(toast, index) in toasts"
      :key="index"
      class="toast show align-items-center"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="d-flex">
        <div class="toast-body d-flex align-items-center">
          <div
            v-if="toast.type === 'success'"
            class="bg-success rounded-3 px-1 d-inline-block"
          >
            <i class="bi bi-check text-white" />
          </div>
          <div
            v-if="toast.type === 'error'"
            class="bg-danger rounded-3 px-1 d-inline-block"
          >
            <i class="bi bi-code text-white" />
          </div>
          <div
            class="d-inline-block ms-4"
            v-html="toast.message"
          />
        </div>
        <button
          type="button"
          class="btn-close me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/event.service"

export default {
  name: "ToastComponent",
  data() {
    return {
      toasts: [],
    }
  },
  mounted() {
    EventService.on("toast", this.addToast)
  },
  unmounted() {
    EventService.all.clear()
  },
  methods: {
    addToast(toast) {
      const key = Date.now().toString(36)
      const { type, message, duration } = toast
      this.toasts.push({
        key,
        type,
        message,
      })
      setTimeout(() => this.removeToast(key), duration ?? 5000)
    },
    removeToast(key) {
      this.toasts = this.toasts.filter((value) => {
        return value.key !== key
      })
    },
  },
}
</script>

<style scoped>
.toast {
  background: white;
}
</style>
